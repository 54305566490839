/*--------------------------------------------------------------
5. Video Popup
----------------------------------------------------------------*/
.cs-pd-video .cs_video_open,
.cs-sample-img .cs_video_open {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 68px;
  transition: all 0.3s ease;
  line-height: 48px;
}


.cs-pd-video .cs_video_open:hover,
.cs-sample-img .cs_video_open:hover {
  color: rgba(255, 255, 255, 0.7);
}

.cs_video_popup {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  left: -100%;
  transition-delay: 0.3s;
}

.cs_video_popup.active {
  left: 0;
  transition-delay: 0s;
  left: 0;
}

.cs_video_popup-overlay {
  position: absolute;
  left: 0;
  right: 0;
  background: #000;
  transition: all 0.4s ease-out;
  opacity: 0;
}

.cs_video_popup.active .cs_video_popup-overlay {
  opacity: 0.8;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.cs_video_popup-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  text-align: center;
  transition: all 0.4s ease-out;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  padding: 15px;
}

.cs_video_popup.active .cs_video_popup-content {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.cs_video_popup-content:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.cs_video_popup-container {
  display: inline-block;
  position: relative;
  text-align: left;
  background: #fff;
  max-width: 1380px;
  width: 100%;
  vertical-align: middle;
}

.cs_video_popup-container .embed-responsive {
  width: 100%;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.embed-responsive-16by9::before {
  display: block;
  content: '';
  padding-top: 56.25%;
}

.embed-responsive iframe,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.cs_video_popup-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: #d90d0d;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.cs_video_popup iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.cs_video_popup-close:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  transform: rotate(-45deg);
  transition: all 0.4s ease-in-out;
}

.cs_video_popup-close:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 1px;
  background: #fff;
  margin-left: -10px;
  transform: rotate(45deg);
  transition: all 0.4s ease-in-out;
}

.cs_video_popup-close:hover:before,
.cs_video_popup-close:hover:after {
  background: #000;
}

.cs_video_popup-layer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.cs_video_popup-align {
  overflow: hidden;
}

/*End Video Popup*/
