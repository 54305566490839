/*--------------------------------------------------------------
  4. Slider
----------------------------------------------------------------*/
.cs_slider {
  position: relative;
}
.slick-slide {
  > div {
    display: flex;
    > .cs_slide {
      flex: none;
      width: 100%;
    }
  }
}
.cs_gap_30 {
  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
  .slick-list {
    margin-left: -15px;
    margin-right: -15px;
    @media (max-width: 575px) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.cs_gap_24 {
  .slick-slide {
    padding-left: 12px;
    padding-right: 12px;
  }
  .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.slick-arrow {
  cursor: pointer;
}
.slick-arrow.slick-hidden {
  display: none !important;
}
.cs_testimonial_slider {
  .cs_slider_activate {
    width: 63vw;
    @media (max-width: 991px) {
      width: 100vw;
    }
  }
}
.cs_service_slider {
  .cs_slider_activate {
    width: 92vw;
  }
  // .slick-list {
  //   overflow: initial;
  // }
  // .slick-slide {
  //   opacity: 0;
  //   transition: all 0.3s ease;
  //   &.slick-active {
  //     opacity: 1;
  //   }
  // }

  .cs_service.cs_style_1 {
    width: 390px;
    @media (max-width: 410px) {
      width: 300px;
    }
  }
  @media (max-width: 410px) {
    .slick-slide {
      padding-left: 6px;
      padding-right: 6px;
    }
    .slick-list {
      margin-left: -6px;
      margin-right: -6px;
    }
  }
  .cs_post.cs_style_1 {
    width: 420px;
  }
  .slick-list {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: -40px;
    margin-bottom: -40px;
  }
}
.cs_hero_slider {
  .cs_slider_navigation {
    top: 51%;
    right: 16.3%;
    @media (max-width: 991px) {
      right: 15px;
    }
  }
  @media (max-width: 575px) {
    .cs_slider_navigation {
      right: 15px;
      right: initial;
      bottom: 15px;
      top: initial;
      flex-direction: row !important;
      gap: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.cs_project_slider_2,
.cs_testimonial_slider_2 {
  position: relative;
  .cs_slider_prev,
  .cs_slider_next {
    top: 50%;
    transform: translateY(-50%);
  }
  .cs_slider_prev {
    left: -10%;
    @media (max-width: 1600px) {
      left: 24px;
    }
  }
  .cs_slider_next {
    right: -10%;
    @media (max-width: 1600px) {
      right: 24px;
    }
  }
}
.cs_testimonial_slider_2,
.cs_service_slider_2 {
  .slick-slide {
    .shadow-sm,
    .shadow {
      box-shadow: initial !important;
    }
    &.slick-active {
      .shadow-sm {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05) !important;
      }
      .shadow {
        box-shadow: 0px 4px 60px rgba(139, 139, 139, 0.15) !important;
      }
    }
  }
}

.cs_service_slider_2 {
  .slick-list {
    overflow: initial;
    padding: 38px;
  }
  .cs_slider_activate {
    overflow: hidden;
    margin: -38px;
  }
}

.cs_testimonial_slider_2 {
  .slick-list {
    padding-top: 38px;
    padding-bottom: 38px;
    margin-top: -38px;
    margin-bottom: -38px;
  }
  // .cs_slider_activate {
  //   overflow: hidden;
  //   margin-top: -38px;
  //   margin-bottom: -38px;
  // }
}

.cs-pagination.cs_style_1 {
  display: flex;
  justify-content: center;
  margin-top: 70px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inherit !important;
  }

  li {
    height: 16px;
    width: 16px;
    background-color: $accent;
    border: 1px solid $accent;
    border-radius: 50%;
    margin: 0 8px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    &::before {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $accent;
    }
  }

  button {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .cs-hidden_desktop {
    display: none !important;
  }
}
@media screen and (max-width: 991px) {
  .cs-hidden_mobile {
    display: none !important;
  }
}
.slick-dots {
  display: flex;
  justify-content: center;
  gap: 14px;
  margin-top: 50px;
  list-style: none;
  overflow: hidden;
  button {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 0;
    line-height: 0;
  }
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  li {
    height: 10px;
    width: 10px;
    background-color: $accent;
    opacity: 1;
    border-radius: 1.6em;
    position: relative;
    transition: all 0.4s ease;
    &.slick-active {
      width: 25px;
    }
  }
}
