/*--------------------------------------------------------------
6. Header
----------------------------------------------------------------*/

.cs_site_header {
  position: absolute;
  width: 100%;
  z-index: 101;
  left: 0;
  top: 0px;
  transition: all 0.4s ease;
}
.cs_site_header.cs_style_1 {
  .cs_main_header {
    background-color: rgba(24, 25, 29, 0.5);
  }
  .cs_main_header_in,
  .cs_top_header_in {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
  .cs_main_header_in {
    height: 95px;
  }
  .cs_top_header_in {
    height: 45px;
  }
  .cs_main_header_right {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &.cs_type_1 {
    .cs_main_header {
      background-color: transparent;
      border-bottom: 1px solid #535353;
    }
  }
  &.cs-sticky-active {
    background-color: #000;
    box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
  }
}
.cs_top_header {
  background: #18191d;
  border-bottom: 1px solid #3e3e3e;
}
.cs_top_nav {
  list-style: none;
  li {
    &:not(:last-child) {
      &::after {
        content: '/';
        margin: 0 15px;
        color: $secondary;
        position: relative;
        top: 1px;
      }
    }
  }
}

.cs_site_header_full_width .container {
  max-width: 100%;
  padding: 0 90px;
}

.cs_site_header_style1 {
  border-bottom: 1px solid #1a188d;

  .cs_main_header_right {
    display: flex;
    align-items: center;
  }

  .cs_nav .cs_nav_list > li.current-menu-item > a:before {
    bottom: -4px;
    background-color: $white;
  }
}
.cs_header_search_form {
  box-shadow: 0px 4px 4px rgba(193, 193, 193, 0.25);
  width: 350px;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  input {
    height: 60px;
    background: #f3f3f3;
    border: 1px solid rgba(62, 62, 62, 0.2);
    border-radius: inherit;
    outline: none;
    width: 100%;
    padding: 5px 70px 5px 22px;
    transition: all 0.3s ease;
    &:focus {
      border-color: rgba(62, 62, 62, 0.4);
    }
  }
  .cs_header_search_sumbit_btn {
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    height: 56px;
    width: 60px;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    &:hover {
      color: $accent;
    }
  }
}
.cs_header_search_wrap.active {
  .cs_header_search_form {
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 1199px) {
  .cs_main_header .container {
    max-width: 100%;
  }

  .cs_site_header.cs_style_1 {
    .cs_nav {
      display: flex;
    }
  }
}

.cs_nav {
  color: #fff;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.cs_sticky_header {
  position: fixed !important;
  width: 100%;
  z-index: 999;
}
.cs-gescout_sticky {
  position: fixed !important;
  top: -110px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  background-color: #000;
}

.cs-gescout_show {
  top: 0 !important;
  opacity: 1;
  visibility: visible;
}

.cs_site_branding {
  display: inline-block;
  + .cs_nav {
    margin-left: 65px;
  }
}

.cs_header_contact {
  display: flex;
  align-items: center;
  .cs_header_contact_icon {
    flex: none;
    margin-right: 12px;
  }
}
.cs_toolbox {
  display: flex;
  align-items: center;
  > * {
    &:not(:first-child) {
      margin-left: 40px;
    }
  }
}
.cs_header_search_btn {
  transition: all 0.4s ease;
  color: #fff;
  cursor: pointer;
  &:hover {
    color: $accent;
  }
}
.cs_site_header.cs_style_1.cs_color_1 {
  background-color: #fff;
  .cs_main_header {
    border-color: #d6d6d6;
  }
  .cs_nav_list {
    > li {
      > a {
        color: $primary;
        &:hover {
          color: $accent;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .cs_main_header {
    position: relative;

    .container-fluid {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .cs_main_header_center,
  .cs_top_header_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cs_site_header.cs_style_1 {
    .cs_main_header_center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      max-width: calc(100% - 300px);
    }
    .cs_main_header_left {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .cs_nav {
    display: flex;
    align-items: center;
    height: 100%;
    color: $white;

    .cs_nav_list {
      display: flex !important;
      flex-wrap: wrap;
      height: inherit;
      li:not(.cs-mega_menu) {
        position: relative;
      }

      > li {
        margin-right: 60px;
        height: inherit;

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 10px 0;
          display: inline-flex;
          position: relative;
          height: inherit;
          align-items: center;
        }

        > ul {
          left: 0;
          top: calc(100% + 15px);
        }

        &:hover {
          > ul {
            top: 90%;
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
          }
        }
        &.menu-item-has-children {
          > a {
            position: relative;
            &::after {
              content: '\2b';
              font-family: 'Font Awesome 6 Free';
              font-weight: 900;
              display: inline-block;
              border-left: 0;
              border-top: 0;
              margin-left: 6px;
              position: relative;
              top: -1px;
              font-size: 12px;
              color: currentColor;
            }
          }
        }
      }

      ul {
        width: 230px;
        background-color: #18191d;
        position: absolute;
        padding: 10px 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        display: block !important;
        border-radius: 0px 0px 5px 5px;
        transition: all 0.1s ease;
        border: 1px solid rgba(255, 255, 255, 0.05);
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.6em;

        li {
          &:not(:last-child) {
            a {
              position: relative;
              &::after {
                content: '';
                position: absolute;
                height: 1px;
                width: calc(100% - 40px);
                left: 20px;
                bottom: 0;
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
          }
          &:hover {
            ul {
              top: 0px;
            }

            > ul {
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }
          }
        }

        a {
          display: block;
          line-height: inherit;
          padding: 10px 20px;
        }

        ul {
          top: 15px;
          left: 100%;
        }
      }
    }
  }
  .cs-munu_toggle,
  .cs-munu_dropdown_toggle {
    display: none;
  }
}

@media screen and (max-width: 1550px) {
  .cs_site_header_full_width .container {
    padding: 0 30px;
  }
  .cs_site_branding + .cs_nav {
    margin-left: 35px;
  }
  .cs_toolbox > *:not(:first-child) {
    margin-left: 25px;
  }
  .cs_nav .cs_nav_list > li {
    margin-right: 35px;
  }
}

@media screen and (max-width: 1199px) {
  .cs_nav .cs_nav_list > li {
    margin-right: 0;
  }
  .cs_site_header.cs_style_1 .cs_main_header_right {
    padding-right: 55px;
  }
  .cs_site_header_full_width .container {
    padding: 0 15px;
  }
  .cs-munu_dropdown_toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 20px;
    top: 5px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 10px;
      background-color: currentColor;
      transition: all 0.3s ease;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  .cs_nav {
    .cs_nav_list {
      position: absolute;
      width: 100vw;
      left: -15px;
      background-color: #181818;
      padding: 10px 0;
      display: none;
      top: 100%;
      border-top: 1px solid #4d4d4d52;
      border-bottom: 1px solid #4d4d4d52;
      overflow: auto;
      max-height: calc(100vh - 80px);
      line-height: 1.6em;

      ul {
        padding-left: 15px;
        display: none;
        text-transform: capitalize;
      }

      a {
        display: block;
        padding: 8px 20px;
      }
    }

    .menu-item-has-children {
      position: relative;
    }
  }

  /*Mobile Menu Button*/
  .cs-munu_toggle {
    display: inline-block;
    width: 30px;
    height: 27px;
    cursor: pointer;
    position: absolute;
    top: 27px;
    right: 30px;

    span,
    span:before,
    span:after {
      width: 100%;
      height: 2px;
      background-color: currentColor;
      display: block;
    }

    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;

      &:before {
        content: '';
        position: absolute;
        margin-top: -9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }

      &:after {
        content: '';
        position: absolute;
        margin-top: 9px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
    }
  }

  .cs_site_header.cs_style_1 .cs-munu_toggle {
    top: 50%;
    right: 0px;
    margin-top: -13px;
  }

  .cs-toggle_active {
    span {
      background-color: rgba(0, 0, 0, 0);
      transition-delay: 0.2s;

      &:before {
        margin-top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.2s;
      }

      &:after {
        margin-top: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.2s;
      }
    }
  }

  .cs_nav .cs_nav_list a {
    position: relative;
  }

  .cs_site_header.cs_style_1 .cs_main_header_in {
    height: 80px;
  }

  .cs_site_header .current-menu-item > a:before {
    display: none;
  }
  .cs_site_header.cs_style_1 {
    top: 0;
  }
  .cs_site_header.cs_style_1.cs_color_1 {
    .cs-munu_toggle,
    .cs-munu_dropdown_toggle,
    .cs_nav .cs_nav_list ul {
      color: $primary;
    }
    .cs_nav .cs_nav_list {
      background-color: #fff;
    }
  }
}

@media screen and (max-width: 991px) {
  .cs_site_header .container {
    max-width: 100%;
  }
  .cs_site_header.cs_style_1 {
    .cs-action_box > *:not(:last-child) {
      margin-right: 25px;
    }
  }
  .cs_site_header.cs_style_1 .cs_btn {
    padding: 8px;
  }
}
@media screen and (max-width: 767px) {
  .cs_site_header.cs_style_1 {
    .cs_top_header_in {
      justify-content: center;
    }
    .cs_top_header_left {
      display: none;
    }
  }
}
@media screen and (max-width: 575px) {
  .cs_site_header.cs_style_1 .cs_btn {
    span {
      display: none;
    }
    svg {
      margin-right: 0;
      width: 20px;
      height: 20px;
    }
  }
}
@media screen and (max-width: 500px) {
  .cs_header_contact {
    display: none;
  }
}
